import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";

interface Employee {
  name?: string;
  email?: string;
  position?: string;
  phone?: string;
  image: Image;
}

interface Props {
  employees: Employee[];
}

const Employees: React.FC<Props> = ({ employees = [] }) => {
  return (
    <div className="employees mw hs vs-xl">
      <div className="grid">
        {employees.map((employee, index) => (
          <div className="employee-card" key={index}>
            {employee.image && (
              <div className="image">
                <img
                  src={transformImageUrl(employee.image, {
                    width: 360,
                    height: 400,
                    fit: "cover",
                  })}
                  alt={employee.image.altText}
                  loading="lazy"
                />
              </div>
            )}
            <div className="content">
              {employee.name && <h3 className="h6">{employee.name}</h3>}
              {employee.position && <p>{employee.position}</p>}
              {employee.email && (
                <p>
                  <a href={`mailto:${employee.email}`}>{employee.email}</a>
                </p>
              )}
              {employee.phone && (
                <p>
                  <a href={`tel:${employee.phone}`}>{employee.phone}</a>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Employees;
